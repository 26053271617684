<template>
  <div class="px-4 p-sm-5 container">
    <div class="heading d-flex justify-content-between mb-5">
      <h2>Synchronisation Shopify</h2>
    </div>

    <template v-if="isLoading">
      <b-spinner></b-spinner>
    </template>
    <template v-else-if="isImportInProgress">
      <div class="d-flex align-items-center">
        <div class="spinner-wrapper">
          <b-spinner></b-spinner>
        </div>
        <div class="ml-4">Importation en cours...</div>
      </div>
      <div class="mt-3">Ce processus peut prendre plusieurs minutes selon la quantité de données à synchroniser.</div>
      <div class="mt-3">Vous recevrez automatiquement une notification par courriel lorsque l'import sera terminé.</div>
    </template>
    <template v-else>
      <div>Nous avons trouvé {{ productCount }} produits dans votre boutique.</div>
      <div class="mt-3">Cliquez sur le bouton ci-dessous pour démarrer l'import. Le processus importera l'ensemble de vos produits. Par la suite, vous pourrez toujours supprimer ou dépublier les articles que vous ne souhaitez pas afficher sur Le bon panier.</div>

      <div class="mt-5">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="syncNewProductCheck" v-model="isAutoImportEnabled">
          <label class="custom-control-label" for="syncNewProductCheck">Synchroniser automatiquement les nouveaux produits</label>
        </div>
      </div>

      <button class="btn btn-primary btn-large mt-4" @click="startImport">Démarrer l'import</button>
    </template>
  </div>
</template>

<script>
import http from '@/utils/http'
import userData from '@/mixins/user-data'

export default {
  mixins: [userData],
  data () {
    return {
      isLoading: false,
      isImportInProgress: false,
      productCount: null,
      isAutoImportEnabled: true,
      checkInterval: null
    }
  },
  methods: {
    async fetchData () {
      this.isLoading = true

      await this.fetchLatestImport()

      if (!this.isImportInProgress) {
        await this.fetchShopifyProductCount()
      }

      this.isLoading = false
    },
    async fetchLatestImport () {
      const { data: latestImport } = await http.get(`/v1/import-tasks/storeId/${this.currentStoreId}/latest`)

      if (latestImport && ['pending', 'running'].includes(latestImport.status)) {
        this.isImportInProgress = true
      } else {
        this.isImportInProgress = false
      }
    },
    async fetchShopifyProductCount () {
      const { data } = await http.get(`/v1/connect/shopify/stores/${this.currentStoreId}/product-count`)
      this.productCount = data.count || 0
    },
    async startImport () {
      try {
        await http.post('/v1/import-tasks', {
          provider: 'shopify',
          storeId: this.currentStoreId,
          importNewProducts: this.isAutoImportEnabled
        })

        this.isImportInProgress = true
      } catch {
        this.$toasted.error("Un erreur s'est produite. Veuillez réessayer dans quelques instants.")
      }
    }
  },
  created () {
    this.fetchData()
  },
  beforeDestroy () {
    if (this.checkInterval) {
      clearInterval(this.checkInterval)
    }
  },
  watch: {
    isImportInProgress (value, oldValue) {
      if (value) {
        this.checkInterval = setInterval(async () => {
          await this.fetchLatestImport()
        }, 10000)
      }

      if (!value && oldValue) {
        if (this.checkInterval) {
          clearInterval(this.checkInterval)
        }

        this.$router.push('/products')
      }
    }
  }
}
</script>
