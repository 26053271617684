<template>
  <app-layout>
    <div class="px-4 p-sm-5 container">
      <div class="mb-4">
        <router-link class="" to="/connected-store" active-class="active">
          <fa-icon :icon="['fas', 'chevron-left']" size="sm"></fa-icon>&nbsp;Retour
        </router-link>
      </div>

      <div class="heading d-flex justify-content-between mb-5">
        <h2>Connexion Shopify</h2>
      </div>

      <p>Veuillez entrer l'identifiant de votre boutique Shopify.</p>

      <div class="row">
        <div class="col-12 col-lg-6">
          <div class="input-group input-group-lg">
            <input type="text" class="form-control" v-model="shopName">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2">.myshopify.com</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <button class="btn btn-primary" :disabled="!shopName || isProcessing" @click="submit">Continuer</button>
      </div>
    </div>
  </app-layout>
</template>

<script>
import http from '@/utils/http'
import userData from '@/mixins/user-data'

export default {
  mixins: [userData],
  data () {
    return {
      shopName: '',
      isProcessing: false
    }
  },
  methods: {
    async submit () {
      this.isProcessing = true

      try {
        await http.post(`/v1/stores/${this.currentStoreId}/connected-stores/shopify`, {
          shop: this.shopName
        })

        this.$router.push('/connected-store')
      } catch {
        this.$toasted.error('Un erreur est survenue, veuillez réessayer dans quelques instants.')
        this.isProcessing = false
      }
    }
  }
}
</script>
