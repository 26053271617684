<template>
  <div class="px-4 p-sm-5 container">
    <template v-if="!installLink">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Demande en traitement</h2>
      </div>

      <p>Nous avons bien reçu votre demande!</p>

      <p>La connexion à une boutique externe Shopify nécessite une légère intervention technique de notre côté qui ne devrait pas prendre trop de temps.</p>

      <div class="subtitle">Prochaines étapes</div>

      <ul>
        <li>Vous allez prochainement recevoir par courriel un lien pour connecter notre plateforme à votre compte Shopify.</li>
        <li>Vous pouvez également revenir sur cette page, le lien d'installation sera visible ici.</li>
      </ul>

      <p>Pour toute question, n'hésitez pas à communiquer avec notre équipe, il nous fera plaisir de vous aider!</p>
    </template>

    <template v-else>
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Connexion Shopify</h2>
      </div>

      <p>Pour installer l'app de synchronisation dans votre panneau d'administration Shopify, veuillez cliquer sur le bouton ci-dessous.</p>

      <button class="btn btn-primary mt-4" @click="redirectToInstallLink">Installer l'app Shopify</button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    connectedStore: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    installLink () {
      return this.connectedStore.data && this.connectedStore.data.installLink ? this.connectedStore.data.installLink : null
    }
  },
  methods: {
    redirectToInstallLink () {
      window.location.href = this.installLink
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  font-size: 1rem;
}
.subtitle {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 16px;
}
</style>
