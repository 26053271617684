<template>
  <div class="shopify-store-status flex-grow-1">
    <pending-view v-if="status !== 'ok'" :connected-store="connectedStore" />
    <sync-view v-else />
  </div>
</template>

<script>
import PendingView from './shopify/PendingView'
import SyncView from './shopify/SyncView'

export default {
  components: {
    PendingView,
    SyncView
  },
  props: {
    connectedStore: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    status () {
      return this.connectedStore && this.connectedStore.data ? this.connectedStore.data.status : 'initiated'
    }
  }
}
</script>
