<template>
  <app-layout>
    <template v-if="isLoading">
      <div class="px-4 p-sm-5 container">
        <b-spinner></b-spinner>
      </div>
    </template>

    <template v-else>
      <get-started v-if="!connectedStoreProvider" />

      <template v-else>
        <component :is="statusComponent" v-bind="{ connectedStore }" />
      </template>
    </template>
  </app-layout>
</template>

<script>
import GetStarted from '@/components/connected-store/GetStarted'
import ShopifyStoreStatus from '@/components/connected-store/ShopifyStoreStatus'
import userData from '@/mixins/user-data'
import http from '@/utils/http'

export default {
  mixins: [userData],
  components: {
    GetStarted
  },
  data () {
    return {
      isLoading: true,
      connectedStore: null
    }
  },
  computed: {
    connectedStoreProvider () {
      return this.connectedStore ? this.connectedStore.provider : null
    },
    statusComponent () {
      // TODO: display status component based on provider, when more platforms will be added
      return ShopifyStoreStatus
    }
  },
  methods: {
    async fetchData () {
      this.isLoading = true

      const { data } = await http.get(`/v1/stores/${this.currentStoreId}/connected-store`)

      if (data && data.id) {
        this.connectedStore = data
      }

      this.isLoading = false
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
